.window-work-phase .window-content {
  max-height: 100%;
  min-height: 100%;
}

#image-popup {
  padding: 0;
  margin: 0;
  z-index: 10;
  max-height: 97%;
  max-width: 97%;
  object-fit: fill;
  cursor: zoom-out;
}

.popup-image {
  max-width: 100%;
  cursor: zoom-out;
}

.gallery-image {
  cursor: zoom-in;
  height: 100%;
  position: relative;
}

.gallery-image img {
  object-fit: cover;
  aspect-ratio: 16/9;
}
