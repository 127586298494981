.cd-display {
  font-size: 100px;
  font-size: 10;
}

.child-container {
  width: 100% !important;
  padding: 1rem;
  padding-right: 0;
}

.volume-control {
  margin-top: -1rem;
}

.analog-timer {
  display: flex;
  justify-content: center;
  flex: 1;
}

/* @media screen and (min-width: 600px) {
  .cd-display {
    font-size: 100px;
  }
}

@media screen and (min-width: 600px) {
  .cd-display {
    font-size: 100px;
  }
}
 */
