$gap: 4px;

.pictograms {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  padding: 0;
  gap: $gap;
}

.pictogramWrapper {
  $count: 3;
  width: calc(100% / $count - #{($count - 1) * $gap});
  cursor: zoom-in;
}

.pictogramImage {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 16/9;
}

.focusedPictogramWrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
  cursor: zoom-out;
}

.focusedPictogramImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
