.base-window-soundboard {
  height: 100% !important;
}

.base-window-soundboard .MuiCardContent-root {
  height: 80% !important;
}

#content-container-soundboard {
  height: 100% !important;
}

#soundboardButtonWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;

  column-gap: 0.5rem;
  row-gap: 0.5rem;

  height: 100% !important;
}

.sound-button,
.video-button {
  width: 200px;
  flex-grow: 1;
}

.add-file-button {
  width: 255px;
}

.soundboardVideo {
  position: absolute;
  inset: 5px;
  width: 100%;
  max-width: 100%;
  max-height: calc(100% - 10px);
  object-fit: contain;
  cursor: pointer;
}

.tab-list {
  margin-bottom: 2em;
}
