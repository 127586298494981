.is-hidden {
  display: none !important;
}

@font-face {
  font-family: "Rubik";
  src: url("./theme/Rubik-VariableFont_wght.ttf");
}

.text-rubik {
  font-family: "Rubik", sans-serif !important;
}

.text-abeezee {
  font-family: "ABeeZee", sans-serif !important;
}

.drag-handle {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
