@font-face {
  font-family: "Rubik";
  src: url("./Rubik-VariableFont_wght.ttf");
}

@font-face {
  font-family: "ABeeZee";
  src: url("./ABeeZee/ABeeZee-Regular.ttf");
}

@font-face {
  font-family: "Grundschrift";
  src: url("./Grundschrift/Grundschrift-Regular.otf");
}

@font-face {
  font-family: "Druckschrift95";
  src: url("druckschrift95.ttf");
}

@font-face {
  font-family: "ComicRelief";
  src: url("./ComicRelief/ComicRelief-Regular.woff2");
}
