.window {
  width: 100%;
  height: 100%;
  min-width: 500px;
  min-height: 300px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.478);
  box-shadow: 15;
  padding: 2px;
}

.window-title {
  color: #000;
  margin: auto; /* remove when re-adding reset button */
}

.window-header-background {
  position: absolute;
  inset: 0;
}

.window-content {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
}
