.menu-button-parent {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.5em;
  padding: 0.5em;

  .menu-button {
    background: #ffffff80;

    &:hover,
    &:focus {
      background: #ffffffc0;
    }
  }
}
