#RandomGeneratorWrapper {
  height: 100%;
  width: 100%;
  min-width: 550px;
}

#RandomGeneratorContent {
  height: 100%;
  width: 100%;
}

.tabs {
  display: flex !important;
  justify-content: center !important;
}
