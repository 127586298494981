.timer-view {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.timer-digits {
  font-size: 3.5rem;
  width: 2em;
  text-align: center;
}

.timer-digits::-webkit-inner-spin-button,
.timer-digits::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.button-container-digits {
  width: 100%;
  text-align: center;
}

.buttons-container-start-stop {
  display: flex;
  justify-content: center;
  margin-left: 3.5rem !important;
}

.stack {
  flex-grow: 1;
}
