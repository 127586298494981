.legal-text {
  color: var(--text-color);
  max-width: 600px;
  margin: 50px auto;
}

.legal-text h1 {
  text-align: center;
  font-size: 4vh;
}
.legal-text h2 {
  text-align: left;
  padding: 0;
  margin: 30px 0 20px 0;
}

.legal-text h6 {
  text-align: center;
  padding: 0;
  margin-top: -8px;
}

.legal-text p {
  padding: 0px 5px;
}

.imprint-privacy {
  position: fixed;
  bottom: 2rem;
  right: 4rem;
  z-index: 11;
  opacity: 50%;
  display: flex;
  flex-direction: column;
}

.donation-button {
  position: fixed;
  top: 92vh;
  left: 4rem;
  height: 40px;
  display: flex;
  gap: 1em;
}

.donation-button button,
.donation-button label {
  background-color: #fffd;
}

.donation-button button:hover,
.donation-button label:hover {
  background-color: white;
}

.ish-logo {
  opacity: 0.55;
  height: 40px;
}

.ish-logo img {
  margin: auto;
  height: 100%;
}

.ish-logo:hover {
  opacity: 1;
}
