.spinwheel-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-stack {
  display: flex;
  justify-content: center;
}

.wheel {
  transform: scale(0.75) translateY(-4rem);
}

.activeButton {
  background-color: #ff705f !important;
  color: #fff !important;
}

// TODO: Make this fixed height and force scrolling, but.. it doesn't work
.textarea-wrapper textarea {
  font-size: 1rem !important;
  width: 100%;
  height: 100%;
  max-height: 100% !important;
  min-width: 100%;
  resize: none;
  overflow-y: scroll;
}
